export default class TimeEntryProfileEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#time_entry_profile_uuid") ? (elem.querySelector("#time_entry_profile_uuid") as HTMLInputElement).value : null,
            name: (elem.querySelector("#time_entry_profile_name") as HTMLInputElement).value,
            superior_id: elem.querySelector("#time_entry_profile_superior_id") ? (elem.querySelector("#time_entry_profile_superior_id") as HTMLInputElement).value : null,

            autobook: (elem.querySelector("#time_entry_profile_autobook") as HTMLInputElement) ? (elem.querySelector("#time_entry_profile_autobook") as HTMLInputElement).checked : false,
            surcharge_night: (elem.querySelector("#time_entry_profile_surcharge_night") as HTMLInputElement) ? (elem.querySelector("#time_entry_profile_surcharge_night") as HTMLInputElement).checked : false,
            surcharge_saturday: (elem.querySelector("#time_entry_profile_surcharge_saturday") as HTMLInputElement) ? (elem.querySelector("#time_entry_profile_surcharge_saturday") as HTMLInputElement).checked : false,
            surcharge_sunday: (elem.querySelector("#time_entry_profile_surcharge_sunday") as HTMLInputElement) ? (elem.querySelector("#time_entry_profile_surcharge_sunday") as HTMLInputElement).checked : false,
            surcharge_holiday: (elem.querySelector("#time_entry_profile_surcharge_holiday") as HTMLInputElement) ? (elem.querySelector("#time_entry_profile_surcharge_holiday") as HTMLInputElement).checked : false,
            config: (elem.querySelector("#time_entry_profile_config_mon_from") as HTMLInputElement) ? {
                mon: {
                    from: (elem.querySelector("#time_entry_profile_config_mon_from") as HTMLInputElement).value,
                    to: (elem.querySelector("#time_entry_profile_config_mon_to") as HTMLInputElement).value,
                    planHours: (elem.querySelector("#time_entry_profile_config_mon_planHours") as HTMLInputElement).value,
                    maxHours: (elem.querySelector("#time_entry_profile_config_mon_maxHours") as HTMLInputElement).value,
                },
                tue: {
                    from: (elem.querySelector("#time_entry_profile_config_tue_from") as HTMLInputElement).value,
                    to: (elem.querySelector("#time_entry_profile_config_tue_to") as HTMLInputElement).value,
                    planHours: (elem.querySelector("#time_entry_profile_config_tue_planHours") as HTMLInputElement).value,
                    maxHours: (elem.querySelector("#time_entry_profile_config_tue_maxHours") as HTMLInputElement).value,
                },
                wed: {
                    from: (elem.querySelector("#time_entry_profile_config_wed_from") as HTMLInputElement).value,
                    to: (elem.querySelector("#time_entry_profile_config_wed_to") as HTMLInputElement).value,
                    planHours: (elem.querySelector("#time_entry_profile_config_wed_planHours") as HTMLInputElement).value,
                    maxHours: (elem.querySelector("#time_entry_profile_config_wed_maxHours") as HTMLInputElement).value,
                },
                thu: {
                    from: (elem.querySelector("#time_entry_profile_config_thu_from") as HTMLInputElement).value,
                    to: (elem.querySelector("#time_entry_profile_config_thu_to") as HTMLInputElement).value,
                    planHours: (elem.querySelector("#time_entry_profile_config_thu_planHours") as HTMLInputElement).value,
                    maxHours: (elem.querySelector("#time_entry_profile_config_thu_maxHours") as HTMLInputElement).value,
                },
                fri: {
                    from: (elem.querySelector("#time_entry_profile_config_fri_from") as HTMLInputElement).value,
                    to: (elem.querySelector("#time_entry_profile_config_fri_to") as HTMLInputElement).value,
                    planHours: (elem.querySelector("#time_entry_profile_config_fri_planHours") as HTMLInputElement).value,
                    maxHours: (elem.querySelector("#time_entry_profile_config_fri_maxHours") as HTMLInputElement).value,
                },
                sat: {
                    from: (elem.querySelector("#time_entry_profile_config_sat_from") as HTMLInputElement).value,
                    to: (elem.querySelector("#time_entry_profile_config_sat_to") as HTMLInputElement).value,
                    planHours: (elem.querySelector("#time_entry_profile_config_sat_planHours") as HTMLInputElement).value,
                    maxHours: (elem.querySelector("#time_entry_profile_config_sat_maxHours") as HTMLInputElement).value,
                },
                sun: {
                    from: (elem.querySelector("#time_entry_profile_config_sun_from") as HTMLInputElement).value,
                    to: (elem.querySelector("#time_entry_profile_config_sun_to") as HTMLInputElement).value,
                    planHours: (elem.querySelector("#time_entry_profile_config_sun_planHours") as HTMLInputElement).value,
                    maxHours: (elem.querySelector("#time_entry_profile_config_sun_maxHours") as HTMLInputElement).value,
                }
            } : {}
        }
    }
}